





























































































































































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import { RegraPorEstado } from '@/models/fiscal/RegraPorEstado'
import SeletorDeEstado from '@/components/ui/SeletorDeEstado.vue'
import CamposDeImposto from './shareds/CamposDeImposto.vue'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import CamposDeTipoDeOperacaoFiscal from './shareds/CamposDeTipoDeOperacaoFiscal.vue'
import { CodigoEDescricao } from '@/models'
import type { RegimeFiscal } from '@/models'
import {
	FindRegraPorEstadoUseCase,
	FindICMSUseCase,
	FindPISUseCase,
	FindCOFINSUseCase,
	SaveRegraPorEstadoUseCase,
} from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import { sortConverter } from '@/shareds/paginacao'
import { DataOptions } from 'vuetify'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		SeletorDeEstado,
		CamposDeImposto,
		CampoDePercentual,
		CamposDeTipoDeOperacaoFiscal,
	},
})
export default class TelaDeRegraPorEstado extends Vue {
	@Ref() campoDeEstado!: SeletorDeEstado
	@Prop({ type: String}) ufOrigem!: string
	@Prop({ type: String}) idRegraAplicacao!: string
	@Prop({ type: String}) idRegraImposto!: string
	@Prop({ type: String}) regimeFiscal!: RegimeFiscal

	findRegraPorEstadoUseCase = new FindRegraPorEstadoUseCase()
	saveRegraPorEstadoUseCase = new SaveRegraPorEstadoUseCase()

	icmsDefault: CodigoEDescricao | null = null
	pisDefault: CodigoEDescricao | null = null
	cofinsDefault: CodigoEDescricao | null = null

	tab = null

	quantidadeDeEstados = 0

	cancelToken: CancelTokenSource | null = null
	regrasPorEstado: RegraPorEstado[] = []

	regraPorEstado: RegraPorEstado | null = null

	loadingCopia = false

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 50,
		sortBy: [ 'ufDestino' ],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	salvando = false

	mostra = false
	icmsst = 'ICMS ST'
	selection = 1
	
	estadoSelecionadoId: string | null = null

	created() {
		this.carregar()
	}

	async carregar() {
		this.icmsDefault = (await new FindICMSUseCase().find({ codigo: '00' })).content[0]
		this.pisDefault = (await new FindPISUseCase().find({ codigo: '06' })).content[0]
		this.cofinsDefault = (await new FindCOFINSUseCase().find({ codigo: '06' })).content[0]

		if (this.cancelToken) this.cancelToken.cancel()
		this.cancelToken = axios.CancelToken.source()
		const axiosConfig = {
			cancelToken: this.cancelToken.token,
		}
		const sorted = sortConverter(this.paginacao)

		const itensPaginados = await this.findRegraPorEstadoUseCase.find(this.idRegraAplicacao, this.ufOrigem, {
			sort: [...sorted],
			page: this.paginacao.page - 1 || 0,
			size: this.paginacao.itemsPerPage,
		}, axiosConfig)

		this.regrasPorEstado = itensPaginados.content
		this.quantidadeDeEstados = itensPaginados.totalElements

		if (this.regrasPorEstado.length > 0) {
			this.regraPorEstado = this.regrasPorEstado[0]

			if (Object.keys({ ...this.regraPorEstado.impostos }).length === 0) {
				this.regraPorEstado = this.preencherRegraPorEstado(this.regraPorEstado);
			}
		}

		this.ordenarImpostos()
	}

	get estadosACopiar(): RegraPorEstado[] {
		return this.regrasPorEstado
	}

	async copiarParaSelecionado(regraPorEstadoAba: RegraPorEstado) {
		try {
			this.loadingCopia = true
			if (regraPorEstadoAba.id && this.estadoSelecionadoId) {
				const regraCopiada = await this.saveRegraPorEstadoUseCase.copiar(regraPorEstadoAba.id, this.estadoSelecionadoId)

				const indice = this.regrasPorEstado.findIndex(r => r.id === this.estadoSelecionadoId)

				this.regrasPorEstado.splice(indice, 1, regraCopiada)

				AlertModule.setSuccess(`Regra copiada com sucesso`)
			} else {
				AlertModule.setError('Nenhuma regra selecionada, verifique!')
			}
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.loadingCopia = false
		}
	}

	esconder() {
		this.mostra = false
	}

	voltarParaTelaAnterior() {
		this.$router.back();
	}

	ordenarImpostos() {
		this.regrasPorEstado = this.regrasPorEstado.map((regra: RegraPorEstado)  => {
			if (!regra) return regra

			if (Object.keys({ ...regra.impostos }).length === 0) {
				regra = this.preencherRegraPorEstado(regra);
			}

			return regra
		})
	}

	async salvar() {
		try {
			this.salvando = true
			await this.saveRegraPorEstadoUseCase.save(this.idRegraImposto, this.idRegraAplicacao, this.regrasPorEstado)
			AlertModule.setSuccess(`Regras do estado origem ${this.regraPorEstado?.ufOrigem} foram salvas com sucesso.`)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}
	
	preencherRegraPorEstado(regraPorEstadoNaoPreenchida: RegraPorEstado): RegraPorEstado {
		return {
			id: regraPorEstadoNaoPreenchida.id,
			ufDestino: regraPorEstadoNaoPreenchida != null ? regraPorEstadoNaoPreenchida.ufDestino : '',
			ufOrigem: this.ufOrigem,
			tiposDeOperacoesFiscais: regraPorEstadoNaoPreenchida.tiposDeOperacoesFiscais || [],
			impostos: {
				ICMS: {
					tipoDeImposto: 'ICMS',
					aliquota: 0,
					csts: [
						{
							id: '',
							operacao: 'SAIDA',
							cst:  {
								id: this.icmsDefault?.id,
								codigo: this.icmsDefault?.codigo || '',
								descricao: this.icmsDefault?.descricao || '',
								tipo: 'ICMS',
							},
						},
					],
				},
				PIS: {
					tipoDeImposto: 'PIS',
					aliquota: 0,
					csts: [
						{
							id: '',
							operacao: 'SAIDA',
							cst:  {
								id: this.pisDefault?.id,
								codigo: this.pisDefault?.codigo || '',
								descricao: this.pisDefault?.descricao || '',
								tipo: 'PIS',
							},
						},
						{
							id: '',
							operacao: 'DEVOLUCAO',
							cst:  {
								id: this.pisDefault?.id,
								codigo: this.pisDefault?.codigo || '',
								descricao: this.pisDefault?.descricao || '',
								tipo: 'PIS',
							},
						},
					],
				},
				COFINS: {
					tipoDeImposto: 'COFINS',
					aliquota: 0,
					csts: [
						{
							id: '',
							operacao: 'SAIDA',
							cst:  {
								id: this.cofinsDefault?.id,
								codigo: this.cofinsDefault?.codigo || '',
								descricao: this.cofinsDefault?.descricao || '',
								tipo: 'COFINS',
							},
						},
						{
							id: '',
							operacao: 'DEVOLUCAO',
							cst:  {
								id: this.cofinsDefault?.id,
								codigo: this.cofinsDefault?.codigo || '',
								descricao: this.cofinsDefault?.descricao || '',
								tipo: 'COFINS',
							},
						},
					],
				},
			},
			percentualFCP: regraPorEstadoNaoPreenchida.percentualFCP || 0,
			percentualMVA: regraPorEstadoNaoPreenchida.percentualMVA,
			regraAtiva: regraPorEstadoNaoPreenchida.regraAtiva,
			reduzIcms: regraPorEstadoNaoPreenchida.reduzIcms,
			incluirFrete:  regraPorEstadoNaoPreenchida.incluirFrete,
			codigosDeBeneficio: regraPorEstadoNaoPreenchida.codigosDeBeneficio,
			codigoCreditoPresumido: regraPorEstadoNaoPreenchida.codigoCreditoPresumido,
			aliquotaCreditoPresumido: regraPorEstadoNaoPreenchida.aliquotaCreditoPresumido,
		}
	}
}

